import {Component, OnInit} from '@angular/core';
import {of} from 'rxjs';
import {Team} from '../../Model/Team';
import {TeamStandingsService} from '../Services/team-standings.service';


// const rosterData: Team[] = [
//     {
//       name: 'Team 1',
//       roster: [{round: 1, name: 'Name', hr: 75},
//         {round: 2, name: 'Name', hr: 75},
//         {round: 3, name: 'Name', hr: 75},
//         {round: 4, name: 'Name', hr: 75},
//         {round: 5, name: 'Name', hr: 75},
//         {round: 6, name: 'Name', hr: 75},
//         {round: 7, name: 'Name', hr: 75}],
//       topFive: 75,
//       total: 100
//     }
//   ]
// ;

@Component({
  selector: 'app-team-standings',
  templateUrl: './team-standings.component.html',
  styleUrls: ['./team-standings.component.scss']
})
export class TeamStandingsComponent implements OnInit {
  teams$ = this.teamStandingsService.GetTeamStandings('2024');


  displayedColumns: string[] = ['round', 'name', 'hr'];


  constructor(private teamStandingsService: TeamStandingsService) {
  }

  ngOnInit(): void {

  }

}
