<div class="container">
  <div *ngIf="(teams$ | async) as team">
    <div fxLayout="row wrap">
      <div fxLayoutAlign="space-around center"
           fxLayoutGap="64px"
           fxFlex.lg
           fxLayoutGap.lt-lg="32px"
           fxFlex="1 1 calc(25% - 32px)"
           fxFlex.lt-lg="100%"
           fxLayout.lt-lg="column"
           *ngFor="let currentTeam of team; let i = index;"
           style="margin-bottom: 30px;">
        <mat-card style="min-width: 325px;">
          <mat-card-header>
            <mat-card-title>
              {{currentTeam.Name}}
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <table fxFill mat-table [dataSource]="currentTeam.Roster" style="margin: auto;">
              <ng-container matColumnDef="round">
                <th mat-header-cell *matHeaderCellDef>Round</th>
                <td mat-cell *matCellDef="let element"> {{element.Round}} </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
              </ng-container>

              <ng-container matColumnDef="hr">
                <th mat-header-cell *matHeaderCellDef>HR</th>
                <td mat-cell *matCellDef="let element"> {{element.HR}} </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>


            <div fxLayout="row" style="text-align: center; margin-top: 3em;">
              <div fxFlex="50">
                <h2>Top 5</h2>
                <h2>{{currentTeam.TopFive}}</h2>
              </div>
              <div fxFlex="50">
                <h2>Total</h2>
                <h2>{{currentTeam.TotalHR}}</h2>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
