<div class="container">
  <div *ngIf="(standings$ | async) as data">
    <div fxLayoutAlign="space-around center"
         fxLayoutGap="64px"
         fxLayout="row"
         fxLayout.lt-lg="column">
      <div fxFlex="0 0 calc(30% - 64px)">
        <mat-card style="min-width: 325px;">
          <mat-card-header>
            <mat-card-title>Standings</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <table fxFill mat-table [dataSource]="data.Standings">
              <ng-container matColumnDef="team">
                <th mat-header-cell *matHeaderCellDef>Team</th>
                <td mat-cell *matCellDef="let element"> {{element.Team}} </td>
              </ng-container>

              <ng-container matColumnDef="hr">
                <th mat-header-cell *matHeaderCellDef>HR</th>
                <td mat-cell *matCellDef="let element"> {{element.HrCount}} </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="standingsColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: standingsColumns;"></tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxFlex="0 0 calc(40% - 64px)">
        <mat-card style="min-width: 325px;">
          <mat-card-header>
            <mat-card-title>HR Feed</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <table fxFill mat-table [dataSource]="data.HrFeed">
              <ng-container matColumnDef="player">
                <th mat-header-cell *matHeaderCellDef>Player</th>
                <td mat-cell *matCellDef="let element"> {{element.Player}} </td>
              </ng-container>

              <ng-container matColumnDef="team">
                <th mat-header-cell *matHeaderCellDef>Team</th>
                <td mat-cell *matCellDef="let element"> {{element.Team}} </td>
              </ng-container>

              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>Time</th>
                <td mat-cell *matCellDef="let element"> {{element.Date | date:'short'}} </td>
              </ng-container>

              <ng-container matColumnDef="hr">
                <th mat-header-cell *matHeaderCellDef>HR</th>
                <td mat-cell *matCellDef="let element"> {{element.HrCount}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="hrFeedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: hrFeedColumns;"></tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxFlex="0 0 calc(30% - 64px)">
        <mat-card style="min-width: 325px;">
          <mat-card-header>
            <mat-card-title>Top Hitter</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <table fxFill mat-table [dataSource]="data.TopHitters">
              <ng-container matColumnDef="player">
                <th mat-header-cell *matHeaderCellDef>Player</th>
                <td mat-cell *matCellDef="let element"> {{element.Player}} </td>
              </ng-container>

              <ng-container matColumnDef="team">
                <th mat-header-cell *matHeaderCellDef>Team</th>
                <td mat-cell *matCellDef="let element"> {{element.Team}} </td>
              </ng-container>

              <ng-container matColumnDef="hr">
                <th mat-header-cell *matHeaderCellDef>HR</th>
                <td mat-cell *matCellDef="let element"> {{element.HrCount}} </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="topHitterColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: topHitterColumns;"></tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>


    </div>

  </div>
</div>
