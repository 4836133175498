import {Component, OnInit} from '@angular/core';
import {LeagueStandingsService} from '../Services/league-standings.service';

@Component({
  selector: 'app-leaguestandings',
  templateUrl: './leaguestandings.component.html',
  styleUrls: ['./leaguestandings.component.scss']
})
export class LeaguestandingsComponent implements OnInit {

  standings$ = this.leagueStandingsService.GetLeagueStandings('2024');

  standingsColumns: string[] = ['team', 'hr'];
  topHitterColumns: string[] = ['player', 'team', 'hr'];
  hrFeedColumns: string[] = ['player', 'team', 'date', 'hr'];

  constructor(private leagueStandingsService: LeagueStandingsService) {
  }

  ngOnInit(): void {
  }

}
