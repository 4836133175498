import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LeagueStandings} from '../../Model/LeagueStandings';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeagueStandingsService {

  constructor(private http: HttpClient) {
  }

  GetLeagueStandings(seasonYear: string): Observable<LeagueStandings> {
    return this.http.get<LeagueStandings>(`${environment.host}/LeagueStandings`, {params: {seasonYear}});
  }
}
